import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Background = styled.div`
  background-color: ${(props) => props.color};
`;

const Path = styled.path`
  fill: ${(props) => props.color};
`;

function Divider({ topColor, bottomColor }) {
  return (
    <Background color={topColor}>
      <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 50">
        <Path
          color={bottomColor}
          d="M0 13.58C12.8 15 19.91 15.78 21.33 15.94C42.67 18.47 85.33 22.92 128 20.69C170.67 18.47 213.33 8.69 256 11.23C298.67 13.58 341.33 27.81 384 34.92C426.67 42.03 469.33 42.03 512 37.27C554.67 32.69 597.33 22.92 618.67 18.34C620.09 18.02 627.2 16.44 640 13.58L640 51C627.2 51 620.09 51 618.67 51C597.33 51 554.67 51 512 51C469.33 51 426.67 51 384 51C341.33 51 298.67 51 256 51C213.33 51 170.67 51 128 51C85.33 51 42.67 51 21.33 51C19.91 51 12.8 51 0 51L0 13.58Z"
          id="a4MqADYF9R"
        ></Path>
      </svg>
    </Background>
  );
}

Divider.propTypes = {
  topColor: PropTypes.string.isRequired,
  bottomColor: PropTypes.string.isRequired,
};

export default Divider;
