import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Icon({ icon, color, text, size, className }) {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={icon} color={color} size={size} />
      {text && <h3 className="uk-margin-small-top uk-text-bold">{text}</h3>}
    </div>
  );
}

Icon.defaultProps = {
  color: null,
  text: null,
  size: '5x',
};

Icon.propTypes = {
  icon: PropTypes.object.isRequired,
  color: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
