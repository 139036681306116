import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Overlay = styled.div`
  width: 100%;
`;

const HeroImage = styled(Img)`
  max-height: 100vh;
`;

function Hero() {
  const { site, heroBackground, logo } = useStaticQuery(QUERY);

  return (
    <div className="uk-cover-background">
      <HeroImage
        className="uk-height-viewport"
        fluid={heroBackground.childImageSharp.fluid}
        backgroundColor="#ffffff"
        alt=""
      />
      <div className="uk-position-cover">
        <Overlay className="uk-overlay uk-overlay-default uk-position-center">
          <div className="uk-flex uk-flex-center uk-flex-middle">
            <Img
              className="uk-width-1-2@m"
              fluid={logo.childImageSharp.fluid}
              alt={`Logo ${site.siteMetadata.title}`}
            />
          </div>
        </Overlay>
      </div>
    </div>
  );
}

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    heroBackground: file(relativePath: { eq: "pattern.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default Hero;
