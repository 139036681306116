import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Navbar from '../components/Navbar';
import Section from '../components/Section';
import Card from '../components/Card';
import Divider from '../components/Divider';
import { colors } from '../components/constants';

import Hero from '../views/Hero';
import Icons from '../views/Icons';
import ProductList from '../views/ProductList';
// import InstagramFeed from '../views/InstagramFeed';
import ContactUs from '../views/ContactUs';

function IndexPage() {
  return (
    <Layout
      unwrap
      nav={
        <Navbar
          animated
          navLinks={[
            {
              label: '¿Qué es Liberi?',
              href: '#about-us',
            },
            {
              label: 'Productos',
              href: '#products',
            },
            {
              label: 'Contacto',
              href: '#contact',
            },
          ]}
        />
      }
    >
      <SEO />

      <Hero />

      <div id="about-us">
        <Section title="Sobre Nosotros" containerClass="uk-container-xsmall">
          <div className="uk-grid">
            <div className="uk-margin-xlarge-bottom">
              <Card
                title="¿Qué es Liberi?"
                uk-scrollspy="cls: uk-animation-fade; delay: 100"
              >
                <p>
                  <em>Liberi</em> es una marca que apuesta por alternativas
                  prácticas, higiénicas y eco-amigables en la higiene femenina.
                  Bajo el lema <em>Libertad sin Tabúes</em>, vemos en nuestros
                  productos una oportunidad de apertura mental a las prácticas
                  convencionales con un mayor poder y autonomía.
                </p>
              </Card>
            </div>
            <div className="uk-width-expand@s">
              <Icons />
            </div>
          </div>
        </Section>
      </div>

      <Divider topColor="#fff" bottomColor={colors.lightRed} />

      <Section
        id="products"
        title="Productos"
        containerClass="uk-container-large"
        backgroundColor={colors.lightRed}
        light
      >
        <ProductList />
      </Section>

      <Divider topColor={colors.lightRed} bottomColor="#fff" />

      {/*
      <Section title="Novedades" containerClass="uk-container-large">
        <InstagramFeed />
      </Section>
      */}

      <Divider topColor="#fff" bottomColor={colors.violet} />

      <Section
        id="contact"
        title="Contacto"
        containerClass="uk-container-small"
        backgroundColor={colors.violet}
        light
      >
        <ContactUs light />
      </Section>
    </Layout>
  );
}

export default IndexPage;
