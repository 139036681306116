import React from 'react';
import {
  faPlusSquare,
  faRecycle,
  faLeaf,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';

import Icon from '../components/Icon';
import { colors } from '../components/constants';

function Icons() {
  return (
    <div
      uk-grid=""
      className="uk-grid uk-grid-large uk-child-width-1-2 uk-child-width-expand@s uk-text-center"
      uk-scrollspy="cls: uk-animation-scale-up; target: > *; delay: 150"
    >
      <Icon icon={faPlusSquare} color={colors.lightBlue} text="Higiénico" />
      <Icon icon={faRecycle} color={colors.blue} text="Reutilizable" />
      <Icon icon={faLeaf} color={colors.green} text="Ecológico" />
      <Icon icon={faHeart} color={colors.red} text="Cómodo" />
    </div>
  );
}

export default Icons;
